import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {path: '',redirectTo:'login',pathMatch:'full'},
  {
    path:'login',
    loadChildren:() => import('./modules').then(m => m.LoginModule)
  },
  {
    path:'home',
    loadChildren:() => import('./modules').then(m => m.InicioModule),
    canActivate: [AuthGuard]
  },
  {
    path:'products',
    loadChildren:() => import('./modules').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
  {
    path:'simulador-oferta',
    loadChildren:() => import('./modules').then(m => m.SimuladorOfertaModule),
    canActivate: [AuthGuard]
  },
  {
    path:'autenticacion',
    loadChildren:() => import('./modules').then(m => m.AutentiacionModule),
    canActivate: [AuthGuard]
  },
  {
    path:'datos-personales',
    loadChildren:() => import('./modules').then(m => m.DatosPersonalesModule),
    canActivate: [AuthGuard]
  },
  {
    path:'resumen',
    loadChildren:() => import('./modules').then(m => m.ResumenModule),
    canActivate: [AuthGuard]
  },
  {
    path:'finalizacion',
    loadChildren:() => import('./modules').then(m => m.FinalizacionModule),
    canActivate: [AuthGuard]
  },
  {
    path:'bandeja-solicitudes',
    loadChildren:() => import('./modules').then(m => m.BandejaSolicitudesModule),
    canActivate: [AuthGuard]
  },
  {
    path:'bandeja-solicitudes-producto',
    loadChildren:() => import('./modules').then(m => m.BandejaSolicitudesProductoModule),
    canActivate: [AuthGuard]
  },
  {
    path:'pasivos-validacion',
    loadChildren:() => import('./modules').then(m => m.PasivosValidacionModule),
    canActivate: [AuthGuard]
  },
  {
    path:'pasivos-validacion-correo',
    loadChildren:() => import('./modules').then(m => m.PasivosValidacionModule),
    canActivate: [AuthGuard]
  },
  {
    path:'pasivos-home',
    loadChildren:() => import('./modules').then(m => m.PasivosHomeModule),
    canActivate: [AuthGuard]
  },
  {
    path:'pasivos-datos-personales',
    loadChildren:() => import('./modules').then(m => m.PasivosDatosPersonalesModule),
    canActivate: [AuthGuard]   
  },
  {
    path:'pasivos-resumen',
    loadChildren:() => import('./modules').then(m => m.PasivosResumenModule),
    canActivate: [AuthGuard]
  },
  {
    path:'pasivos-validacion-biometrica',
    loadChildren:() => import('./modules').then(m => m.PasivosValidacionBiometricaModule),
    canActivate: [AuthGuard]
  },
  {
    path:'admin-usuarios',
    loadChildren:() => import('./modules').then(m => m.AdminUsuariosModule),
    canActivate: [AuthGuard]
  },
  {
    path:'admin-usuarios-acciones',
    loadChildren:() => import('./modules').then(m => m.AdminUsuariosAccionesModule),
    canActivate: [AuthGuard]
  },
  {
    path:'admin-empresas',
    loadChildren:() => import('./modules').then(m => m.AdminEmpresasModule),
    canActivate: [AuthGuard]
  },
  {
    path:'admin-empresas-acciones',
    loadChildren:() => import('./modules').then(m => m.AdminEmpresasAccionesModule),
    canActivate: [AuthGuard]
  },
  {
    path:'admin-parametros',
    loadChildren:() => import('./modules').then(m => m.AdminParametrosModule),
    canActivate: [AuthGuard]
  },
  {
    path:'admin-parametros-acciones',
    loadChildren:() => import('./modules').then(m => m.AdminParametrosAccionesModule),
    canActivate: [AuthGuard]
  },
  {
    path:'reportes',
    loadChildren:() => import('./modules').then(m => m.ReportesModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
