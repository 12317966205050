import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "application/menu";
  private BASE_URL: string = environment.applicationApiEndpoint;
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getMenu(roleId: string) {
    return this.httpClient.get<any[]>(`${this.REQUEST_URL}/GetMenu/${roleId}`);
  }
}
