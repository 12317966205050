import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'app-custom-input-flex',
  templateUrl: './custom-input-flex.component.html',
  styleUrls: ['./custom-input-flex.component.scss']
})
export class CustomInputFlexComponent {
  @Input() placeholderText: string = '';
  @Input() label: string = '';
  @Input() width: string = '190px';
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isError: boolean = false;
  @Input() borderColor: string = '';
  @Input() validateNumber: boolean = false;
  @Input() validateEmail: boolean = false; // Nuevo
  @Input() maxLength: number | null = null;

  onValueChange(value: string): void {
    this.isError = false; // Reiniciar el estado de error

    if (this.validateNumber && !/^\d+$/.test(value)) {
      this.isError = true;
    } else if (this.validateEmail && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      this.isError = true;
    } else if (this.maxLength !== null && value.length > this.maxLength) {
      this.isError = true;
    }

    this.valueChange.emit(value); // Emitir el valor incluso si hay un error, para permitir correcciones
  }
}
