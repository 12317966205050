import { Component, EventEmitter, Input, Output,forwardRef  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  standalone:true,
  imports: [CommonModule,FormsModule],
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDatepickerComponent),
      multi: true
    }
  ],
})
export class CustomDatepickerComponent {

  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() isError: boolean = false;
  @Input() isReadonly: boolean = false;
  @Input() isDisabled: boolean = false;

  onValueChange(value: string): void {
    this.isError = false; // Reiniciar el estado de error
    this.valueChange.emit(value); // Emitir el valor incluso si hay un error, para permitir correcciones
  }
  focusInput(inputElement: HTMLInputElement): void {
    inputElement.focus();
  }
}

