export const environment = {
    production: true,
    identityApiEndpoint: 'https://digitalchannels-apig.dev.banbif.com.pe/pva/api/identity/connect/token',
    loanSimulationApiEndpoint: 'https://digitalchannels-apig.dev.banbif.com.pe/pva/api',
    customerApiEndpoint: 'https://digitalchannels-apig.dev.banbif.com.pe/pva/api',
    optionalInsurancesApiEndpoint: 'https://digitalchannels-apig.dev.banbif.com.pe/pva/api',
    parametersApiEndpoint: 'https://digitalchannels-apig.dev.banbif.com.pe/pva/api',
    applicationApiEndpoint: 'https://digitalchannels-apig.dev.banbif.com.pe/pva/api',
    offersApiEndpoint: 'https://digitalchannels-apig.dev.banbif.com.pe/pva/api',
    customerAccountApiEndpoint: 'https://digitalchannels-apig.dev.banbif.com.pe/pva/api',
    reCaptchaKey: "6LcKZIoqAAAAAF3WiJq9ysR1B8v-_xVmYNdLdd5h"
};